@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* colors */
  --bg: #f8f8f8;
  --primary: #facc15;
  --primary-light: #dbeafe;

  /* fonts */
  --poppins: "Poppins", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
}

*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

body {
  width: 100vw;
  height: 100vh;
  max-width: 100vw !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  font-size: 1rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word !important;
  background: var(--bg);
  box-sizing: border-box;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

#sidebar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: auto;
}

#sidebar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

::-webkit-scrollbar {
  width: 8px;
  @apply bg-gray-200;
}

::-webkit-scrollbar-track {
  @apply bg-transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-400 border-2 border-gray-300;
}

@layer base {
  body {
    @apply font-poppins text-neutral-800;
  }

  button {
    @apply outline-none;
  }

  button:disabled {
    opacity: 50% !important;
    cursor: not-allowed !important;
  }
}

@layer components {
  .form-group {
    @apply w-full text-sm mb-1.5;
  }

  .form-group label {
    @apply block mb-1;
  }

  .form-group input {
    border-width: 1.4px;
    @apply outline-none rounded px-2 py-2 mb-0.5 bg-slate-200 w-full;
  }

  .form-group input[type="file"] {
    border-width: 1.4px;
    @apply w-full bg-white border outline-none rounded px-2 py-2 mb-0.5;
  }

  .form-group textarea {
    border-width: 1.4px;
    @apply outline-none rounded px-2 py-2 mb-0.5 bg-slate-200 w-full;
  }

  .form-group select {
    @apply bg-slate-200 px-2 py-2 rounded outline-none w-full;
  }

  .table {
    @apply w-full max-w-full text-center border-collapse table-auto border;
  }

  .table thead {
    @apply w-full bg-neutral-100 shadow rounded font-semibold text-neutral-800 border-b text-base;
  }

  .table thead th {
    @apply p-3 m-4;
  }

  .table tbody {
    @apply text-neutral-900;
  }

  .table tbody tr {
    @apply border-b;
  }

  .table tbody tr td {
    @apply px-2 py-4;
  }
}

@layer utilities {
  .btn-primary {
    @apply bg-yellow-400 py-2 px-5 text-black rounded font-bold;
  }

  .btn-danger {
    @apply bg-red-500 py-2 px-5 text-white rounded font-bold;
  }
  .btn-success {
    @apply bg-green-500 py-2 px-5 text-white rounded font-bold;
  }

  .btn-danger {
    @apply bg-red-500 py-2 px-5 text-white rounded font-bold;
  }

  .btn-primary-rounded {
    @apply bg-primary rounded-full p-1 text-white;
  }
}
